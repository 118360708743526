var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "orderCommissionForm",
      attrs: {
        model: _vm.orderCommissionForm,
        "validate-on-rule-change": false,
      },
    },
    [
      _c("div", { staticClass: "basic-card-block margin-top-0" }, [
        _c("h1", [_vm._v("Commission")]),
        _c("div", [
          _c(
            "div",
            { staticClass: "div-frac-13" },
            [
              _vm.tradeType === _vm.ORDER_TRADE_TYPE.SALES
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Broker Company",
                        prop: "brokerSysOrganizationId",
                        rules: _vm.rules.reqRule,
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            "value-key": "id",
                            disabled: _vm.propsView === "VIEW_ORDER",
                          },
                          on: { change: _vm.handleBrokerCompanySelect },
                          model: {
                            value:
                              _vm.orderCommissionForm.brokerSysOrganizationId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.orderCommissionForm,
                                "brokerSysOrganizationId",
                                $$v
                              )
                            },
                            expression:
                              "orderCommissionForm.brokerSysOrganizationId",
                          },
                        },
                        _vm._l(
                          _vm.sortByAlphabetical(
                            _vm.creditAssociationList,
                            "counterpartyCompanyName"
                          ),
                          function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.counterpartyCompanyName,
                                value: item.counterpartySysOrganizationId,
                                disabled: item.status !== "ACTIVE",
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "Broker Company" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.orderCommissionForm.brokerCompanyName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.orderCommissionForm,
                              "brokerCompanyName",
                              $$v
                            )
                          },
                          expression: "orderCommissionForm.brokerCompanyName",
                        },
                      }),
                    ],
                    1
                  ),
              _vm.tradeType === _vm.ORDER_TRADE_TYPE.SALES
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Broker Person-In-Charge",
                        prop: "brokerId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            "value-key": "id",
                            disabled: _vm.propsView === "VIEW_ORDER",
                          },
                          model: {
                            value: _vm.orderCommissionForm.brokerId,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderCommissionForm, "brokerId", $$v)
                            },
                            expression: "orderCommissionForm.brokerId",
                          },
                        },
                        _vm._l(
                          _vm.sortByAlphabetical(_vm.customerList, "name"),
                          function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: {
                                label: item.name,
                                value: item.id,
                                disabled:
                                  !item.isActive &&
                                  !_vm.creditAssociation.isOnlineCounterparty,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "Broker Person-In-Charge" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.orderCommissionForm.brokerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.orderCommissionForm, "brokerName", $$v)
                          },
                          expression: "orderCommissionForm.brokerName",
                        },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "div-product-other-costs-info-body" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: {
                    type: "primary",
                    round: "",
                    size: "mini",
                    disabled: _vm.propsView === "VIEW_ORDER",
                  },
                  on: { click: _vm.addCommissionProduct },
                },
                [_vm._v(" Add Commission ")]
              ),
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.orderCommissionForm.commissions,
                    border: "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "Product Name", "min-width": "350px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: `commissions.${scope.$index}.orderProductId`,
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.propsView === "VIEW_ORDER" ||
                                        scope.row.status === "INVOICE_CREATED",
                                      clearable: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.handleOrderCommProductNameSelect(
                                          $event,
                                          scope.$index
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.orderProductId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "orderProductId",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.orderProductId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.commissionProductList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: `${item.sysProductName} (${
                                            item.currency
                                          } ${_vm.numberWithCommas(
                                            item.price,
                                            2
                                          )})`,
                                          value: item.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { "min-width": "150px" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [_c("span", [_vm._v("* ")]), _vm._v("Fee")]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: `commissions.${scope.$index}.fee`,
                                  rules: _vm.rules.reqRule,
                                },
                              },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    controls: false,
                                    precision: 2,
                                    min: null,
                                    disabled:
                                      _vm.propsView === "VIEW_ORDER" ||
                                      scope.row.status === "INVOICE_CREATED",
                                  },
                                  model: {
                                    value: scope.row.fee,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "fee", $$v)
                                    },
                                    expression: "scope.row.fee",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { "min-width": "120px" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("span", [_vm._v("* ")]),
                            _vm._v("Fee Currency"),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: `commissions.${scope.$index}.currency`,
                                  rules: _vm.rules.reqRule,
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { disabled: "" },
                                    model: {
                                      value: scope.row.currency,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "currency", $$v)
                                      },
                                      expression: "scope.row.currency",
                                    },
                                  },
                                  _vm._l(_vm.currencyList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.code,
                                        value: item.code,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { "min-width": "150px" },
                    scopedSlots: _vm._u([
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("span", [_vm._v("* ")]),
                            _vm._v("Fee UOM Type"),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: `commissions.${scope.$index}.uomType`,
                                  rules: _vm.rules.reqRule,
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      disabled:
                                        _vm.propsView === "VIEW_ORDER" ||
                                        !scope.row.orderProductId ||
                                        scope.row.status === "INVOICE_CREATED",
                                    },
                                    model: {
                                      value: scope.row.uomType,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "uomType", $$v)
                                      },
                                      expression: "scope.row.uomType",
                                    },
                                  },
                                  _vm._l(
                                    _vm.ORDER_COMM_UOM_TYPE_OPTIONS,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Amount", "min-width": "150px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-form-item",
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    controls: false,
                                    precision: 2,
                                    min: null,
                                    disabled: "",
                                  },
                                  model: {
                                    value: scope.row.amount,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "amount", $$v)
                                    },
                                    expression: "scope.row.amount",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.tradeType === _vm.ORDER_TRADE_TYPE.SALES
                    ? _c("el-table-column", {
                        staticStyle: { "text-align": "center" },
                        attrs: { label: "Display", "min-width": "80px" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-switch", {
                                    staticStyle: { "margin-bottom": "22px" },
                                    attrs: {
                                      disabled: _vm.propsView === "VIEW_ORDER",
                                    },
                                    model: {
                                      value: scope.row.isDisplayCommission,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "isDisplayCommission",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "scope.row.isDisplayCommission",
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3311957353
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { width: "50px", fixed: "right" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              [
                                scope.row.status !== "INVOICE_CREATED" ||
                                !scope.row.status
                                  ? _c("el-button", {
                                      attrs: {
                                        type: "danger",
                                        icon: "el-icon-delete",
                                        size: "mini",
                                        circle: "",
                                        disabled:
                                          _vm.propsView === "VIEW_ORDER",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeCommissionProduct(
                                            scope.$index
                                          )
                                        },
                                      },
                                    })
                                  : _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "Invoice Created",
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c("el-button", {
                                          staticClass: "el-icon-check",
                                          attrs: {
                                            type: "success",
                                            size: "mini",
                                            circle: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }